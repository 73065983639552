import z from 'zod';
import { parseNumber } from './globalize';
import { refine } from '@conform-to/zod';
import { Prisma, PrismaClient } from '@prisma/client';

export function createLocalizedNumberSchema(decimals = 2) {
    return z.union([
        z.string().transform(value => parseNumber(value)),
        z.number().finite(),
    ])
        .pipe(z.number())
        .transform(value => {
            const factor = Math.pow(10, decimals);
            const roundedValue = Math.round(value * factor);
            return roundedValue / factor;
        });
}

type ModelDelegates = {
    [K in Prisma.ModelName]: PrismaClient[Uncapitalize<K>]
}

export function createForeignKeyValidatedSchema<S extends z.ZodTypeAny, T extends ModelDelegates[keyof ModelDelegates]>(
    baseSchema: S,
    model: T,
    where: (value: z.infer<S>) => Parameters<T['findUnique']>[0]['where'],
    message: string,
) {
    return baseSchema
        .pipe(baseSchema.superRefine((value, ctx) =>
            refine(ctx, {
                validate: () => {
                    return (model as any)?.findUnique({ where: where(value) }).then(Boolean);
                },
                message,
            }),
        ));
}