import cldrDeCurrencies from 'cldr-data/main/de/currencies.json';
import cldrDeNumbers from 'cldr-data/main/de/numbers.json';
import cldrCurrency from 'cldr-data/supplemental/currencyData.json';
import cldrSubtags from 'cldr-data/supplemental/likelySubtags.json';
import cldrNumberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import Globalize from 'globalize';

Globalize.load(cldrSubtags);
Globalize.load(cldrDeNumbers);
Globalize.load(cldrDeCurrencies);
Globalize.load(cldrNumberingSystems);
Globalize.load(cldrCurrency);
Globalize.locale('de');

export const parseNumber = Globalize.numberParser();
